<template>
    <section class="flex flex-col gap-4">
        <MyAccountLegend :help-link="helpLink">
            {{ $t("MyAccount.NotificationAddressesTab") }}
        </MyAccountLegend>

        <div class="flex flex-col gap-2">
            <p>{{ $t("MyAccount.NotificationEmailsDescription") }}</p>

            <div v-if="!addresses || !addresses.length">
                {{ $t("MyAccount.NoAddressesFound") }}
            </div>

            <table v-else class="table table-striped">
                <thead>
                    <tr>
                        <td>{{ $t("MyAccount.EmailLabel") }}</td>
                        <td>{{ $t("Global.Status") }}</td>
                        <td>{{ $t("Global.Delete") }}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="address in addresses" :key="address.Id">
                        <td>{{ address.EmailAddress }}</td>
                        <td>
                            <Badge :variant="badgeVariantsMap[address.StatusEnum]">{{ address.Status }}</Badge>
                        </td>
                        <td>
                            <Button
                                v-if="showDeleteButton(address.Status)"
                                variant="danger"
                                fill="solid"
                                :title="$t('Global.Delete')"
                                @click="confirmRemoveAddress(address)">
                                <i class="fas fa-trash-can" />
                            </Button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <h4>{{ $t("MyAccount.AddNewEmailAddress") }}</h4>

            <ButtonGroup>
                <input
                    id="new-email-entry"
                    v-model="emailText"
                    type="text"
                    placeholder="you@yourdomain.com"
                    :disabled="isBusy"
                    @keyup.enter="addAddress" />
                <Button variant="primary" fill="solid" :disabled="isBusy" @click="addAddress">
                    {{ $t("Global.Add") }}
                </Button>
            </ButtonGroup>

            <p v-if="error" class="text-danger">{{ error }}</p>
        </div>

        <Dialog v-model:open="isDeleteModalOpen" @close="closeDeleteModal">
            <DialogContent id="deleteEmailAddressModal">
                <DialogTitle>
                    {{ $t("MyAccount.ModalWarning_EmailTitle") }}
                </DialogTitle>
                {{ $t("MyAccount.ModalWarning_EmailBody", [pendingDeleteEmail]) }}
                <DialogFooter>
                    <Button variant="default" @click="closeDeleteModal">
                        {{ $t("Global.Cancel") }}
                    </Button>
                    <Button
                        type="submit"
                        variant="danger"
                        fill="solid"
                        @click="removeAddress(emailPendingDelete?.Id ?? 0)">
                        {{ $t("MyAccount.ModalWarning_EmailButton", [pendingDeleteEmail]) }}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    </section>
</template>

<script setup lang="ts">
import { UserNotificationEmailStatus } from "../../utils/types/Address";
import { FetchError } from "ofetch";
import type { Address } from "../../utils/types/Address";
import type { UserNotificationSettings } from "../../utils/types/UserNotificationSettings";
import helpers from "../../utils/PageHelpers.js";
import * as v from "valibot";
import type { BadgeVariants } from "~/components/ui";

const { t } = useI18n();
const api = useApi();

const EmailSchema = v.pipe(
    v.string(),
    v.trim(),
    v.nonEmpty(t("MyAccount.ErrorEmailIsRequired")),
    v.email(t("MyAccount.InvalidEmail"))
);

const error = ref("");
const emailText = ref("");
const emailPendingDelete = ref<Address | null>(null);
const isDeleteModalOpen = ref(false);
const helpLink = helpers.GetDocsUrl("my-account/");
const isModifying = ref(false);

const {
    data: userNotificationSettings,
    status: userNotificationSettingsStatus,
    refresh: updateUserNotifications,
} = await useFetchLocaleApi<UserNotificationSettings>("api/notification-emails", {
    onResponse({ response }) {
        if (response._data.DefaultEmail) {
            emailText.value = response._data.DefaultEmail;
        }
    },
});
const isBusy = computed(() => userNotificationSettingsStatus.value === "pending" || isModifying.value);
const addresses = computed<Address[]>(() => userNotificationSettings.value?.Addresses || []);
const pendingDeleteEmail = computed<string>(() => emailPendingDelete.value?.EmailAddress ?? "");
type BadgeVariantsMap = Record<UserNotificationEmailStatus, BadgeVariants["variant"]>;
const badgeVariantsMap: BadgeVariantsMap = {
    [UserNotificationEmailStatus.Confirmed]: "success",
    [UserNotificationEmailStatus.Unconfirmed]: "warning",
    [UserNotificationEmailStatus.Declined]: "danger",
    [UserNotificationEmailStatus.Invalid]: "danger",
    [UserNotificationEmailStatus.Unsubscribed]: "danger",
};

function showDeleteButton(status: string): boolean {
    return status === t("MyAccount.Status_Unconfirmed") || status === t("MyAccount.Status_Confirmed");
}

function closeDeleteModal() {
    isDeleteModalOpen.value = false;
}
async function addAddress() {
    if (isBusy.value) return;

    const email = emailText.value;
    const result = v.safeParse(EmailSchema, email);

    isModifying.value = true;

    if (!result.success) {
        error.value = result.issues.map((i) => i.message)[0];
        isModifying.value = false;
        return;
    }

    try {
        const formData = new FormData();
        formData.append("EmailAddress", email);

        const response = await api<{ address_id: number; success: boolean }>("api/notification-emails", {
            method: "POST",
            body: formData,
        });

        if (response.success) {
            updateUserNotifications();

            error.value = "";
            emailText.value = "";
        }
    } catch (_error) {
        if (_error instanceof FetchError) {
            error.value = _error.data.Error;
        } else {
            error.value = t("MyAccount.ErrorLastRequest");
        }

        emailText.value = email;
    } finally {
        isModifying.value = false;
    }
}
function confirmRemoveAddress(address: Address) {
    emailPendingDelete.value = address;
    isDeleteModalOpen.value = true;
}
async function removeAddress(id: number) {
    if (isBusy.value || !addresses.value) return;

    isModifying.value = true;

    emailPendingDelete.value = null;

    try {
        const response = await api<{ success: boolean }>(`api/notification-emails/${id}`, {
            method: "DELETE",
        });

        if (response.success) {
            error.value = "";
            updateUserNotifications();
        }
    } catch (_error) {
        error.value = t("MyAccount.ErrorLastRequest");
    } finally {
        isModifying.value = false;
    }

    closeDeleteModal();
}
</script>
