export type Address = {
    Id: number;
    UserId?: number;
    StatusEnum: UserNotificationEmailStatus;
    Status: string;
    EmailAddress: string;
    AddedAt?: Date;
    ConfirmedAt?: Date;
    UpdatedAt?: Date;
};

export enum UserNotificationEmailStatus {
    Unconfirmed = 0,
    Confirmed = 1,
    Declined = 2,
    Unsubscribed = 3,
    Invalid = 4,
}
